<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1 style="text-align: left; padding-left: 20px">{{ translations.tcSponsorAuxiliaryCandidate }}</h1>
    </header>
    <div>
      <page-body class="pb">
        <div class="container">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="mb-0 display-6">{{ translations.tcFillOutForEmailInvitation }}</h5>
            </div>
            <div class="card-body py-3 flex-grow-1">
              <form>
                <div class="form-group">
                  <label class="form-control-label mytext">{{ translations.tcFirstName }}</label>
                  <input type="text" class="form-control form-control-sm input-mask" v-model.trim="forms.firstName"
                    :placeholder="translations.tcFirstName"
                    :class="{ 'is-invalid': submitted && $v.forms.firstName.$error }" />
                  <div class="invalid-feedback" v-if="submitted && !$v.forms.firstName.required">
                    {{ translations.tcFirstNameRequired }}
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">{{ translations.tcLastName }}</label>
                  <input type="text" class="form-control form-control-sm input-mask" v-model="forms.lastName"
                    :placeholder="translations.tcLastName"
                    :class="{ 'is-invalid': submitted && $v.forms.lastName.$error }" />
                  <div class="invalid-feedback" v-if="submitted && !$v.forms.lastName.required">
                    {{ translations.tcLastNameRequired }}
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">{{ translations.tcEmail }}</label>
                  <input type="email" class="form-control form-control-sm" v-model="forms.candidateEmail"
                    :placeholder="translations.tcEmail"
                    :class="{ 'is-invalid': submitted && $v.forms.candidateEmail.$error }" />
                  <div v-if="submitted && $v.forms.candidateEmail.$error" class="invalid-feedback">
                    <span v-if="!$v.forms.candidateEmail.required">{{ translations.tcEmailRequired }}</span>
                    <span v-if="!$v.forms.candidateEmail.email">{{ translations.tcEmailIsInvalid }}</span>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">{{ translations.tcPhoneNumber }}</label>
                  <phone-number-input type="text" class="form-control form-control-sm input-mask" v-model="forms.phone"
                    :placeholder="translations.tcPhoneNumber"
                    :class="{ 'is-invalid': submitted && $v.forms.phone.$error }" />
                  <div v-if="submitted && $v.forms.phone.$error" class="invalid-feedback">
                    <span v-if="!$v.forms.phone.required">{{
                      translations.tcPhoneNumberRequired
                    }}</span>
                    <span v-if="!$v.forms.phone.numeric">{{
                      translations.tcPhoneNumberMustBeNumeric
                    }}</span>
                    <span v-if="$v.forms.phone.numeric && (!$v.forms.phone.minLength || !$v.forms.phone.maxLength)">
                      Phone number must consist of 10 digits only.
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-control-label">Husband's Gideon Member Number</label>
                  <div class="input-group">
                    <input type="text" class="form-control form-control-sm input-mask" v-model="husbandMemberNo"
                      placeholder="Husband's Gideon Member Number" />
                    <b-button variant="primary"
                      class="btn-w-sm flex-0 mr-0 mr-sm-5 w-500-sd mb-3 mb-sm-0 rounded-0 mx-auto pt-0 pb-0 validate-btn"
                      @click="validateMemberNo()">Validate</b-button>
                  </div>
                </div>
                <section class="form-buttons">
                  <b-button variant="primary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
                    @click="sendProspectInfo">{{ translations.tcSend }}</b-button>
                  <b-button variant="tertiary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0"
                    @click="reset">{{ translations.tcCancel }}</b-button>
                </section>
              </form>
            </div>
          </div>
        </div>
      </page-body>
      <IndividualInformation :member_number="husbandMemberNo" :name="individualDetails.name"
        :email="individualDetails.email" :phone="individualDetails.phone" :address="individualDetails.address">
      </IndividualInformation>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { required, email, numeric, maxLength, minLength } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import pageBody from '@/components/page-components/PageBody.vue'
import SecurityBanner from '@/components/SecurityBanner'
import IndividualInformation from '@/components/IndividualInformation.vue'
import { translationMixin } from '@/mixins/translationMixin'
import PhoneNumberInput from '@/components/PhoneNumberInput.vue'

export default {
  name: 'candidate-info',
  mixins: [translationMixin],
  data () {
    return {
      forms: {
        sponsor: null,
        firstName: null,
        lastName: null,
        isAuxiliary: null,
        candidateEmail: null,
        phone: "",
        husbandMemberNumber: null
      },
      husbandMemberNo: "",
      validatedHusbandNumbers: new Map(),
      submitted: true,
      translations: {},
      individualDetails: {
        name: "",
        email: "",
        phone: ""
      }
    }
  },
  components: {
    pageBody,
    IndividualInformation,
    securityBanner: SecurityBanner,
    PhoneNumberInput
  },
  validations: {
    forms: {
      firstName: { required },
      lastName: { required },
      candidateEmail: { required, email },
      phone: { required, numeric, maxLength: maxLength(10), minLength: minLength(10) }
    }

  },
  async created () {
    try {
      this.setLoadingStatus(true)
      await Promise.all([this.getViewTranslations(), this.getComponentTranslations('security-banner')]).then(
        (results) => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
        }
      )
      this.forms.sponsor = this.userId
      this.forms.isAuxiliary = "YES"
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      createCandidateInfo: 'candidateModule/createCandidateInfo',
      locateIndividuals: 'search/locateIndividuals',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    reset () {
      this.$router.push({ path: '/programs/mb/membership' })
    },
    async validateMemberNo (indModal = { showSuccessModal: true }) {

      const showInvalidMemberPopUp = () => {
        this.$swal({
          title: 'Invalid Member No',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          cancelButtonText: this.translations.tcNo,
          reverseButtons: true,
          text: 'Invalid Member No, Please type in the correct Member No',
        })
      }

      let isMemberValidated = false
      if (this.husbandMemberNo === "") {
        return
      }
      this.setLoadingStatus(true)
      let response = await this.locateIndividuals({
        account_id: this.husbandMemberNo
      })
      if (response && this.matchingIndividual !== null) {
        if (indModal.showSuccessModal) {
          this.showModal('individualModal')
        }
        this.validatedHusbandNumbers.set(this.husbandMemberNo, this.matchingIndividual.ind_key)
        this.individualDetails = {
          name: this.matchingIndividual.name_text,
          email: this.matchingIndividual.email_text,
          phone: this.matchingIndividual.phone_text,
          address: this.matchingIndividual.address_block
        }
        isMemberValidated = true
      }
      else {
        showInvalidMemberPopUp()
      }
      this.setLoadingStatus(false)
      return isMemberValidated
    },
    showModal (modalName) {
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async sendProspectInfo () {
      this.$v.$touch()
      const isFormDataCorrect = await this.setCorrectFormData()
      if (!this.$v.forms.$invalid && isFormDataCorrect) {
        this.sendAuxiliaryCandidateInfo()
      }
    },
    async setCorrectFormData () {
      if (this.husbandMemberNo === "") {
        this.forms.husbandMemberNumber = null
      } else if (!this.validatedHusbandNumbers.has(this.husbandMemberNo)) {
        const res = await this.validateMemberNo({ showSuccessModal: false })
        if (!res) return false
      }
      if (this.validatedHusbandNumbers.has(this.husbandMemberNo)) {
        this.forms.husbandMemberNumber = this.validatedHusbandNumbers.get(this.husbandMemberNo)
      }
      this.forms.phone = this.forms.phone.toString()
      return true
    },
    async sendAuxiliaryCandidateInfo () {
      let ss = this.initLoader()
      let response = await this.createCandidateInfo(this.forms)
      if (response === true) {
        ss.hide()
        this.$router.push({ path: '/programs/mb/success/auxiliary' })
      } else {
        ss.hide()
        console.error('An Error occurred: ' + response.message)
        this.$swal({
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          text: 'Something Went wrong.Please try again.'
        })
      }
    },
    initLoader () {
      return this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      })
    },
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      matchingIndividuals: 'search/matchingIndividuals',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
    }),
    allowPage () {
      return this.isInMenu(this.$route.path)
    },
    matchingIndividual () {
      if (this.matchingIndividuals.length > 0) {
        return this.matchingIndividuals[0]
      } return null
    }
  }

}
</script>

<style lang="scss">
.pb {
  margin-bottom: 30px;
}

.dollarsign {
  position: relative;
  height: 0;
  left: -14px;
}

.nonew {
  padding: 30px;
}

.dtext,
.shorttext,
.mediumtext {
  line-height: 13px;
  font-size: 12px;
}

.rtext {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}

.shorttext {
  margin-top: 8px;
}

.mediumtext {
  margin-top: 4px;
}

.brow {
  line-height: 36px;
}

.editing {
  background-color: #35b130;
  color: #ffffff;
  border-radius: 6px;
}

.subtitle {
  margin: 10px 0;
}

@import '@/styles/settings.scss';

.membership {
  .page-body {
    margin-top: 60px;

    h2 {
      margin-bottom: 36px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 52px;

    >.row {
      margin-bottom: 40px;
    }

    .t-container {
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }

    .col {
      @include breakpoint(sm) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }

      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;

        .number-card:last-of-type {
          margin-bottom: 0;
        }
      }

      table {
        width: 100% !important;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
